<template>
  <k-field-group language-prefix="vacancy.fields">
    <k-form-dialog
      v-bind="$attrs"
      :is-update-form="isUpdateForm"
      :panels="panels"
      v-on="$listeners"
    >
      <template #panel.0>
        <v-row
          class="pr-2"
          no-gutters
        >
          <EmployerAutocomplete
            v-model="values.employer"
            field="employerId"
            grid="col-12"
            required
          />
          <LocationAutocomplete
            v-if="values.employer"
            v-model="values.location"
            :employer-id="values.employer.id"
            field="locationId"
            grid="col-12"
            required
          />
          <DepartmentAutocomplete
            v-if="values.location"
            v-model="values.department"
            :location-id="values.location.id"
            field="departmentId"
            grid="col-12"
            required
          />
          <template v-if="values.employer">
            <PersonAutocomplete
              v-model="values.assignedPeople"
              :employer-id="values.employer.id"
              field="assignedEmployerUsers"
              multiple
              required
            />
            <IntermediaryUserAutocomplete
              v-model="values.assignedIntermediaryUsers"
              :fill-default-user="isUpdateForm"
              field="assignedIntermediaryUsers"
              multiple
              required
            />
          </template>
        </v-row>
        <v-row no-gutters>
          <KTextField
            v-model="values.name"
            field="name"
            required
          />
          <KSelect
            v-model="values.vacancyStatusId"
            :items="statuses"
            field="statusId"
            item-text="name"
            item-value="id"
            required
          />
          <KTextarea
            v-model="values.texts.reason"
            field="texts.reason"
          />
        </v-row>
      </template>

      <template #panel.1>
        <v-row no-gutters>
          <KDateField
            v-model="values.startDate"
            :max-date="maxDate"
            :min-date="minimalDate"
            field="startDate"
            required
          />
          <v-col cols="12">
            <KCheckbox
              v-model="hasPeriod"
              field="hasPeriod"
            />
          </v-col>
        </v-row>
        <v-row
          v-if="hasPeriod"
          no-gutters
        >
          <v-col class="col-2 pt-0 pr-2">
            <VTextField
              v-model.number="values.duration"
              :required="!!hasPeriod"
              dense
              type="number"
            />
          </v-col>
          <v-col class="col-3 pt-1">
            <k-text-display>{{ $tc('global.timeUnits.month', values.duration) }}</k-text-display>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            class="pt-5 pb-0"
            cols="12"
          >
            <k-text-display>{{ $t('vacancy.fields.employment') }}</k-text-display>
          </v-col>
          <v-col
            v-for="employmentOption in employmentsOptions"
            :key="employmentOption.id"
            cols="12"
          >
            <VCheckbox
              v-model="values.employments"
              :label="employmentOption.name"
              :value="employmentOption"
              dense
              hide-details
            />
          </v-col>
          <v-col
            class="pt-6"
            cols="12"
          >
            <k-text-display>{{ $t('vacancy.fields.hours') }}</k-text-display>
          </v-col>
          <v-col class="col-2 pt-0 pr-2">
            <VTextField
              v-model.number="values.weekHours"
              dense
              hide-details
              required
              type="number"
            />
          </v-col>
          <v-col class="col-10 pt-1">
            <k-text-display>{{ $t('vacancy.fields.aWeek') }}</k-text-display>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <KTextField
            v-model="values.salary"
            dense
            field="salary"
            grid="col-3 pt-5"
            required
            type="number"
          />
        </v-row>
        <v-row no-gutters>
          <KTextField
            v-model="values.quantity"
            dense
            field="quantity"
            grid="col-3"
            required
            type="number"
          />
        </v-row>
        <v-row no-gutters>
          <KTextarea
            v-model="values.texts.particularities"
            field="texts.particularities"
            grid="col-12"
          />
        </v-row>
      </template>

      <template #panel.2>
        <v-row no-gutters>
          <PositionAutocomplete
            v-model="values.positions"
            field="positions"
            grid="col-12 pr-2"
            multiple
            required
          />
          <BranchAutocomplete
            v-model="values.branch"
            field="branchId"
            grid="col-12 pr-2"
            required
          />
          <KTextField
            v-model="values.experienceYears"
            field="experienceYears"
            grid="col-5"
            required
            type="Number"
          />
        </v-row>
      </template>

      <template #panel.3>
        <v-row no-gutters>
          <KEditorNode
            v-model="values.texts.vacancy"
            field="texts.vacancy"
            grid="col-12"
          />
          <KEditorNode
            v-model="values.texts.company"
            field="texts.company"
            grid="col-12"
          />
          <KEditorNode
            v-model="values.texts.demands"
            field="texts.demands"
            grid="col-12"
          />
          <KEditorNode
            v-model="values.texts.wishes"
            field="texts.wishes"
            grid="col-12"
          />
        </v-row>
      </template>
    </k-form-dialog>
  </k-field-group>
</template>

<script>
import Vacancy from '@/application/models/Vacancy.js';
import KTextDisplay from '@/components/crud/display/KTextDisplay.vue';
import KDateField from '@/components/crud/fields/KDateField.vue';
import KEditorNode from '@/components/crud/fields/KEditorNode';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KSelect from '@/components/crud/fields/KSelect.vue';
import KTextarea from '@/components/crud/fields/KTextarea.vue';
import KTextField from '@/components/crud/fields/KTextField.vue';
import KFormDialog from '@/components/crud/KFormDialog.vue';
import BranchAutocomplete from '@/modules/branch/components/BranchAutocomplete.vue';
import DepartmentAutocomplete from '@/modules/department/components/DepartmentAutocomplete.vue';
import EmployerAutocomplete from '@/modules/employer/components/EmployerAutocomplete.vue';
import LocationAutocomplete from '@/modules/location/components/LocationAutocomplete.vue';
import PersonAutocomplete from '@/modules/person/components/PersonAutocomplete.vue';
import PositionAutocomplete from '@/modules/position/components/PositionAutocomplete.vue';
import IntermediaryUserAutocomplete from '../../intermediaryUser/components/IntermediaryUserAutocomplete.vue';
import dayjs from '@/plugins/dayjs';
import { mapGetters } from 'vuex';
import KCheckbox from '@/components/crud/fields/KCheckbox.vue';

export default {
  name: 'VacancyForm',
  components: {
    IntermediaryUserAutocomplete,
    KCheckbox,
    KEditorNode,
    PersonAutocomplete,
    DepartmentAutocomplete,
    LocationAutocomplete,
    EmployerAutocomplete,
    BranchAutocomplete,
    PositionAutocomplete,
    KDateField,
    KTextarea,
    KTextField,
    KSelect,
    KFieldGroup,
    KFormDialog,
    KTextDisplay,
  },
  data() {
    return {
      hasPeriod: false,
    };
  },
  updated() {
    this.hasPeriod = this.values.duration !== 0;
  },
  computed: {
    ...mapGetters({ employmentsOptions: 'employment/all' }),
    panels() {
      return [
        { name: this.$t('vacancy.tabs.general') },
        { name: this.$t('vacancy.tabs.employment') },
        { name: this.$t('vacancy.tabs.matching') },
        { name: this.$t('vacancy.tabs.texts') },
      ];
    },
    statuses() {
      return [{
        id: 1,
        name: this.$t('vacancy.vacancy_status.open'),
      }, {
        id: 2,
        name: this.$t('vacancy.vacancy_status.closed'),
      }];
    },
    minimalDate() {
      return dayjs().subtract(1, 'month');
    },
    maxDate() {
      return dayjs().add(10, 'year');
    },
  },
  props: {
    values: {
      type: Object,
      default: new Vacancy(),
    },
    isUpdateForm: {
      type: Boolean,
    },
  },
};
</script>

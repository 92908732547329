<template>
  <div id="page-vacancy-index">
    <KCrudLayout :search.sync="searchQuery">
      <template #header>
        {{ $tc('vacancy.title', 2) }}
      </template>
      <template #flow-actions>
        <v-btn
          color="primary"
          depressed
          tile
          @click="handleOpenCreateDialog"
        >
          {{ $t('actions.createResource', { resource: $tc('vacancy.title', 1) }) }}
        </v-btn>
        <VacancyForm
          v-model="createDialog"
          :request="createRequest"
          :title="$tc('vacancy.title',1)"
          :values="createFormValues"
          @change="$refs.table.reload()"
        />
      </template>
      <template #view.list>
        <k-crud-table
          ref="table"
          :headers="crudHeaders"
          :index-request="indexRequest"
          :search="searchQuery"
          language-prefix="vacancy.index"
          resource="vacancy"
          @click:edit="openUpdate"
        >
          <template #item.vacancyStatusId="props">
            <span v-if="props.item.vacancyStatusId === vacancyStatus.OPEN">{{
              $t('vacancy.vacancy_status.open')
            }}</span>
            <span v-else>{{ $t('vacancy.vacancy_status.closed') }}</span>
          </template>
        </k-crud-table>
      </template>
    </KCrudLayout>
    <VacancyForm
      v-model="updateDialog"
      :request="updateRequest"
      :title="$tc('vacancy.title',1)"
      :values="updateFormValues"
      is-update-form
      @change="$refs.table.reload()"
    />
  </div>
</template>

<script>
import { vacancyStatus } from '@/application/enums/vacancy.js';
import eventBus from '@/application/eventBus.ts';
import Vacancy from '@/application/models/Vacancy.js';
import KCrudTable from '@/components/crud/KCrudTable.old.vue';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import { create, index, show, update } from '@/modules/vacancy/api/vacancy.js';
import VacancyForm from '@/modules/vacancy/components/Form.vue';
import { mapActions } from 'vuex';

export default {
  name: 'VacancyIndex',
  components: {
    VacancyForm,
    KCrudTable,
    KCrudLayout,
  },
  data() {
    return {
      searchQuery: '',
      updateDialog: false,
      createDialog: false,
      updateFormValues: new Vacancy(),
      createFormValues: new Vacancy(),
      vacancyStatus,
    };
  },
  computed: {
    crudHeaders() {
      return [
        {
          value: 'employerName',
          language: 'employerName',
        },

        {
          value: 'name',
          language: 'vacancyName',
        },
        {
          value: 'vacancyStatusId',
          language: 'vacancyStatus',
        },
      ];
    },
  },
  created() {
    this.fetchEmployments();
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'vacancy.index' },
        text: this.$tc('vacancy.title', 2),
      },
    ]);
  },
  methods: {
    ...mapActions({ fetchEmployments: 'employment/fetchEmploymentTypes' }),
    handleOpenCreateDialog() {
      this.createFormValues = new Vacancy();
      this.createDialog = true;
    },
    updateRequest() {
      return update(this.updateFormValues);
    },
    createRequest() {
      return create(this.createFormValues);
    },
    indexRequest() {
      return index(...arguments);
    },
    async openUpdate(item) {
      this.updateFormValues = new Vacancy();
      const vacancyShowRequest = await show(item.id);
      this.updateFormValues.mapResponse(vacancyShowRequest.data.data);
      this.updateDialog = true;
    },
  },
};
</script>
